import client from '@/services/';

const state = () => ({
    all: [],
    allRegions: [],
    one: {}
});

const getters = {
    all: state => state.all,
    allRegions: state => state.allRegions,
    one: state => state.one
};

const mutations = {
    SET_ALL_REGIONS: (state, obj) => {
        state.allRegions = obj;
    },
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('user/all');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('user/filtered/'+payload.page, {params: {texte: payload.texte}})
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllAmbassadeur(cxt, payload = {page: 1, spec: ''}) {
        let { data } = await client.get('user/ambassadeur/'+payload.page, {params: {texte: payload.spec}})
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllAssignation(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('user/assignation/filtered/default/'+payload.page, {params: payload});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllAssignationSearch(cxt, payload = {page: 1, data: {}, size: 7, search: {}}) {
        let { data } = await client.post('user/assignation/filtered/default/'+payload.page+'/'+payload.size, payload.data, {params: payload.search});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllUserZone(cxt, payload = {page: 1, regions: [], size: 7, search: {}}) {
        let { data } = await client.post('user/assignation/filtered/default/'+payload.page+'/'+payload.size, payload.regions, {params: payload.search});
        cxt.commit("SET_ALL_REGIONS", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('user/edit/'+payload.id, payload) : await client.post('user/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
        return data;
    },
    
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
