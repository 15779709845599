import client from '@/services/';

const state = () => ({
    uploadedFile: null,
    uploadedFichier: null
});

const getters = {
    uploadedFile: state => state.uploadedFile,
    uploadedFichier: state => state.uploadedFichier
};

const mutations = {
    SET_UPLOADED_FILE: (state, form) => {
        state.uploadedFile = form;
    },
    SET_UPLOADED_FICHIER: (state, form) => {
        state.uploadedFichier = form;
    },
};

const actions = {
    async uploadFileDGI({ commit }, fileData) {
        const { data } = await client.post('file/load/dgi', fileData);
        commit('SET_UPLOADED_FICHIER', data);
        return data;
    },
    async uploadFichier({ commit }, fileData) {
        const { data } = await client.post('file/load/apiex', fileData);
        commit('SET_UPLOADED_FILE', data);
        return data;
    },
    async uploadFichierMembre({ commit }, fileData) {
        const { data } = await client.post('file/load/membre', fileData);
        commit('SET_UPLOADED_FILE', data);
        return data;
    },
    async uploadFichierAhilido({ commit }, fileData) {
        const { data } = await client.post('file/load/membre/ahilido', fileData);
        commit('SET_UPLOADED_FICHIER', data);
        return data;
    },
    async uploadFichierEntreprise({ commit }, fileData) {
        const { data } = await client.post('file/load/membre/entreprises', fileData);
        commit('SET_UPLOADED_FICHIER', data);
        return data;
    },
    async downloadFile({commit}, {fileName, fileData}){
        try {
            const { data } = await client.post(`file/download/${fileName}`, fileData);
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            //create download URL
            const link = document.createElement('a')
            link.href = url;
            link.download = fileName + '.xlsx'
            document.body.appendChild(link);

            link.click()

            commit('SET_UPLOADED_FILE', data);
            return data;
        } catch (error) {
            console.error(error);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
