import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('responsable');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {}) {
        let { data } = await client.get('responsable/'+payload);
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('responsable/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('responsable/edit/'+payload.id, payload) : await client.post('responsable/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
    
        return data;    },
    async del(cxt, id) {
        let { data } = await client.delete('responsable/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async searchContact(cxt, {contactId}){
        let {data} = await client.get('responsable/contact/'+ contactId)
        cxt.commit("SET_ALL",data);
        return data
    },
    async searchFonction(cxt, {fonctionId}){
        let {data} = await client.get('responsable/fonction/'+ fonctionId)
        cxt.commit("SET_ALL",data);
        return data
    },
    async searchClient(cxt, {clientId}){
        let {data} = await client.get('responsable/entreprise/'+ clientId)
        cxt.commit("SET_ALL",data);
        return data
    }, 
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
