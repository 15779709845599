import https from "@/services/";
import Vue from 'vue';

let verrou = false, _retry = 0;


export default {
    middleware: async ({ to, store, next }) => {
        const requiredPermission = to.meta.requiresPermission;
        const user = store.getters['connexion/user'];
        
        // Vérifie si l'utilisateur est connecté
        if (!user) {
            // Redirige l'utilisateur vers la page de connexion s'il n'est pas connecté
            next({ name: 'connexion' });
        } else {

            const utili = JSON.parse(localStorage.getItem('user'));
            // Vérifie si l'utilisateur a la permission requise
            if (requiredPermission && !utili.permissions.some(p => p.libelle === requiredPermission)) {
                // Redirige l'utilisateur vers une page d'erreur ou une autre page appropriée s'il n'a pas la permission requise
                next({ name: 'profil' });
            } else {
                next();
            }
        }
    },

    interceptor(error, store, router) {
        const { response, config } = error;
        const token = Vue.$cookies.get('ahiCCIBlido');

        // Vérifie si aucun token n'est présent
        if (!token) {
            router.replace({ name: 'connexion' });
            return Promise.reject(error);
        }

        if (!response) return Promise.reject(error);

        const { status } = response;

        if (status === 401) {
            return store.dispatch("connexion/logout").then(() =>
                router.replace({
                    name: 'connexion',
                    query: {
                        redirect: (router.currentRoute || {}).fullPath
                    }
                }).then(() => { })
            );
        }

        return Promise.reject(response);
    }
};

