import client from '@/services/';
import { saveAs } from 'file-saver';

const state = () => ({
    all: [],
    one: {},
    close: [],
    groupement: [],
    mandature: [],
    fichier: [],
    service: [],
    uploadedFile: null,
});

const getters = {
    all: state => state.all,
    one: state => state.one,
    close: state => state.close,
    groupement: state => state.groupement,
    mandature: state => state.mandature,
    fichier: state => state.fichier,
    service: state => state.service,
    uploadedFile: state => state.uploadedFile,

};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_SERVICE: (state, obj) => {
        state.service = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    }, 
    SET_CLOSE: (state, obj) => {
        state.close = obj;
    },
    SET_GROUPEMENT: (state, obj) => {
        state.groupement = obj;
    },
    SET_MANDATURE: (state, obj) => {
        state.mandature = obj;
    },
    SET_FICHIER: (state, obj) => {
        state.fichier = obj;
    },
    SET_UPLOADED_FILE: (state, obj) => {
        state.uploadedFile = obj;
    },
}

const actions = {
    async entreprisesDirigeant(cxt, payload = {page: 1, texte: '', secteurActivite: '', regionId: '', ifu: ''},) {
        let data  = await client.get('/portail/rs/'+payload.page, {params: {texte: payload.texte, ifu: payload.ifu, secteurActivite: payload.secteurActivite, regionId: payload.regionId }});
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: '', size: 7, etatEntrepriseCode: '', regionId: '', formeJuridiqueId: '', ifu: '', raisonSociale: '', startDate: '', endDate: ''},) {
        let { data } = payload.texte !== '' ? await client.get('entreprises/filtered/'+payload.page, {params: {texte: payload.texte}}) : await client.get('entreprises/'+payload.page+'/'+payload.size , {params: {etatEntrepriseCode: payload.etatEntrepriseCode, regionId: payload.regionId, formeJuridiqueId: payload.formeJuridiqueId, ifu: payload.ifu, raisonSociale: payload.raisonSociale, startDate: payload.startDate, endDate: payload.endDate}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllPage(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('entreprises/filtered/'+payload.page, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async exportToExcel(cxt, payload = {page: 1, texte: '', size: 7, etatEntrepriseCode: '', regionId: '', formeJuridiqueId: '', ifu: '', raisonSociale: '', startDate: '', endDate: ''}) {
        try {
            const { data } = payload.texte !== '' ? await client.get('entreprises/export/filtered/'+payload.page, {params: {texte: payload.texte}}) : await client.get('entreprises/export/'+payload.page+'/'+payload.size, {
                params: {
                    etatEntrepriseCode: payload.etatEntrepriseCode,
                    regionId: payload.regionId,
                    formeJuridiqueId: payload.formeJuridiqueId,
                    ifu: payload.ifu,
                    raisonSociale: payload.raisonSociale,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                }
            });
            cxt.commit("SET_GROUPEMENT", data);
            return data;
        } catch (error) {
            console.error("Erreur lors de l'exportation", error);
        }
    },    
    async exportToPdf(cxt, payload = {page: 1, size: 7, etatEntrepriseCode: '', regionId: '', formeJuridiqueId: '', ifu: '', raisonSociale: '', startDate: '', endDate: ''}) {
        try {
            const { data } = await client.get('entreprises/export/pdf/'+payload.page+'/'+payload.size, {
                params: {
                    etatEntrepriseCode: payload.etatEntrepriseCode,
                    regionId: payload.regionId,
                    formeJuridiqueId: payload.formeJuridiqueId,
                    ifu: payload.ifu,
                    raisonSociale: payload.raisonSociale,
                    startDate: payload.startDate,
                    endDate: payload.endDate,
                },
                responseType: 'blob'  // Assurez-vous que la réponse est de type blob
            });
    
            // Crée une URL blob pour le fichier PDF
            const url = window.URL.createObjectURL(new Blob([data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'entreprises.pdf');  // Nom du fichier PDF à télécharger
            document.body.appendChild(link);
            link.click();
    
            // Nettoie le DOM après le téléchargement
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);  // Libère l'URL blob après l'utilisation
    
        } catch (error) {
            console.error("Erreur lors de l'exportation du fichier PDF", error);
        }
    },
    async getAllDirigeantP(cxt, payload = {page: 1, texte: ''},) {
        let { data } = await client.get('dirigeantEntreprises/'+payload.page, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async creates(cxt, payload) {
        let { data } = payload.id ? await client.put('dirigeantEntreprises/edit/'+payload.id, payload) : await client.post('dirigeantEntreprises/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
    
        return data;    
    },
    async getOne(cxt, id) {
        let { data } = await client.get('entreprises/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('entreprises/edit/'+payload.id, payload) : await client.post('entreprises/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
        return data;    
    },
    async getStatistiqueRegion(cxt) {
        let { data } = await client.get('/entreprises/statistiques/region');
        cxt.commit("SET_CLOSE", data);
        return data;
    },
    async getStatistiqueFormeJuridique(cxt) {
        let { data } = await client.get('/entreprises/statistiques/formeJuridique');
        cxt.commit("SET_CLOSE", data);
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('entreprises/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async dirigeantEntreprise(cxt, {ifuEntreprise, entreprisesId, }){
        let {data} = await client.get('dirigeantEntreprises/ifu/'+ ifuEntreprise+'/'+entreprisesId);
        cxt.commit("SET_CLOSE",data);
        return data
    }, 
    async transfert(cxt, payload){
        let {data} = await client.post('membre/transfert/entreprises', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async rechercheEntreprises(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('entreprises/filtered/'+payload.page, {params: {texte: payload.texte}})
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async groupementList(cxt, payload = {page: 1, size: 7, ifu: ''}) {
        let { data } = await client.get('/groupement/entreprises/'+payload.page+'/'+payload.size+ '/'+ payload.ifu);
        cxt.commit("SET_GROUPEMENT", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async mandatureList(cxt, payload = {page: 1, size: 7, ifu: ''}) {
        let { data } = await client.get('/mandature/entreprises/'+payload.page+'/'+payload.size+ '/'+ payload.ifu);
        cxt.commit("SET_MANDATURE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getEntrepriseFile(cxt, ifu) {
        let { data } = await client.get('entreprises/fichiers/'+ifu);
        cxt.commit("SET_FICHIER", data);
        return data;
    },
    async uploadFichier({ commit }, { naturePieceId, entreprisesId, publique, gratuit, payload }) {
        try {
          const { data } = await client.post(`fichier/add/entreprises/${naturePieceId}/${entreprisesId}/${publique}/${gratuit}`, payload);
          commit('SET_FICHIER', data);
          return data;
      
        } catch (error) {
          console.error('Erreur lors de l\'ajout du document :', error);
          throw error;
        }
    },
    async serviceFourniList(cxt, payload = {page: 1, size: 7, ifu: ''}) {
        let { data } = await client.get('/serviceFourniEntreprise/'+payload.page+'/'+payload.size+ '/'+ payload.ifu);
        cxt.commit("SET_SERVICE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async exportationMembre({commit}) {
        try {
            let {data} = await client.get('/entreprises/export', { responseType: 'arraybuffer' });
      
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
            saveAs(blob, 'Entreprises.xlsx');
      
            commit('SET_UPLOADED_FILE', data);
            } catch (error) {
            console.error(error);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};