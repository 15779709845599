import front from './front';
import back from './back';
import {VUE_APP_BASE_URL} from '@/config/config';

export default {
    mode: 'history',
    base: VUE_APP_BASE_URL,
    linkExactActiveClass: 'active',
    routes: [
        ...front,
        ...back,
        {
            path: '*', hidden: true,
            redirect: to => ({
                name: 'connexion',
                query: { redirect: to.fullPath }
            })
        }
    ],
}