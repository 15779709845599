import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('demande');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = { page: 1, texte: '' }) {
        let { data } = await client.get('demande/' + payload.page, { params: payload });
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number + 1,
            content: data.content || []
        })
        return data;
    },
    async getOne(cxt, payload) {
        let { data } = await client.get('demande/one/'+payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('demande/edit/' + payload.id, payload) : await client.post('demande/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('demande/delete/' + id);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
