import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('membre');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('membre/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = await client.put('portail/demande/add', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('membre/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};