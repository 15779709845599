import client from '@/services/';

const state = () => ({
    uploadedFile: null,
    all: [], 
    one: {}
});

const getters = {
    uploadedFile: state => state.uploadedFile,
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_UPLOADED_FILE: (state, obj) => {
        state.uploadedFile = obj;
    },
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async uploadFichier({ commit }, { naturePieceId, membreId, publique, gratuit, payload }) {
        try {
          const { data } = await client.post(`fichier/add/${naturePieceId}/${membreId}/${publique}/${gratuit}`, payload);
          commit('SET_UPLOADED_FILE', data);
          return data;
      
        } catch (error) {
          console.error('Erreur lors de l\'ajout du document :', error);
          throw error;
        }
    },
    async getAll(cxt, payload) {
        let { data } = await client.get('fichier');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async update(cxt, payload) {
        let { data } = await client.put('fichier/edit/'+payload.id, payload);
        cxt.commit("SET_ONE", data);
        return data;    
    },
    async del(cxt, id) {
        let { data } = await client.delete('fichier/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async downloadFichier({ commit }, { id, fileName, contentType }) {
        try {
            const response = await client.get(`fichier/download/${id}`, { responseType: 'arraybuffer' });
      
            const blob = new Blob([response.data], { type: contentType });
      
            saveAs(blob, fileName);

            commit('SET_UPLOADED_FILE', response.data);
            } catch (error) {
            console.error(error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
