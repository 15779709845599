const routes = [
    {
        path: '/', name: 'home',
        redirect: to => ({
            name: 'connexion',
            query: { redirect: to.fullPath }
        })
    },
    {
        path: '/inscription/:slug', name: 'signup',
        props: to => ({value: to.params}),
        component: () => import('@/views/Signup')
    },
];

export default [
    {
        path: '/',
        component: () => import('@/views/espace/Home'),
        children: [...routes]
    },
]