import Vue from 'vue'
import App from './App.vue'
import Vuex from "vuex";
import Vuer from "vue-router";
import helps from "./helpers/";
import https from './services/';
import Vuem from "vue-moment";
import Paginate from 'vue-pagination-2';
import PrimeVue from 'primevue/config';
import Sidebar from 'primevue/sidebar';
import Divider from 'primevue/divider';
import PickList from 'primevue/picklist';
import InputText from 'primevue/inputtext'
import Dropdown from 'primevue/dropdown'
import Button from 'primevue/button';
import ProgressBar from 'primevue/progressbar';
import Dialog from 'primevue/dialog';
import Menu from 'primevue/menu';
import Tag from 'primevue/tag'
import Steps from 'primevue/steps';
import SplitButton from 'primevue/splitbutton';
import stores from "./stores";
import routes from "./routes";
import Spinner from 'vue-spinkit';
import { extend, localize } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import _fr from 'vee-validate/dist/locale/fr.json';
import VueLoading from 'vuejs-loading-plugin';
import Vuet from "vue-sweetalert2";
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
import moment from 'moment';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import MultiSelect from 'vue-multiselect'
import PMultiSelect from 'primevue/multiselect';
import OverlayPanel from 'primevue/overlaypanel';
import AutoComplete from 'primevue/autocomplete';
import Chart from 'primevue/chart';
import CCalendar from '@/components/Calendar.vue';
import XLSX from "xlsx";
import JsonExcel from "vue-json-excel";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Calendar from 'primevue/calendar';
import Card from "primevue/card";
import Toolbar from 'primevue/toolbar';
import vSelect from 'vue-select';
import html2pdf from 'html2pdf.js';


// Import one of the available themes
//import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import "vue-select/dist/vue-select.css";
import "./assets/js/menu-toggle.js"

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

Vue.config.productionTip = false
Vue.component("Toolbar", Toolbar);
Vue.component("Card", Card);
Vue.component("downloadExcel", JsonExcel);
Vue.component('PCalendar', CCalendar)
Vue.component('Calendar', Calendar);
Vue.component('Spinner', Spinner);
Vue.component('Menu', Menu);
Vue.component('Paginate', Paginate);
Vue.component('Sidebar', Sidebar);
Vue.component('Dialog', Dialog);
Vue.component('Button', Button);
Vue.component('Divider', Divider);
Vue.component('ProgressBar', ProgressBar);
Vue.component('SplitButton', SplitButton);
Vue.component('Dropdown', Dropdown)
Vue.component('PMultiSelect', PMultiSelect);
Vue.component('PickList', PickList);
Vue.component('InputText', InputText);
Vue.component('Steps', Steps);
Vue.component('OverlayPanel', OverlayPanel);
Vue.component('AutoComplete', AutoComplete);
Vue.component('Chart', Chart);
Vue.component('Tag', Tag);
Vue.component('TabView', TabView);
Vue.component('TabPanel', TabPanel);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('MultiSelect', MultiSelect);
Vue.component('vSelect', vSelect);
Vue.component('html2pdf', html2pdf);
//Vue.component('XLSX', XLSX);
Vue.use(VueCookies);
Vue.use(PrimeVue);
Vue.use(Vuem, { moment });
Vue.use(Vuer).use(Vuet).use(Vuex).use(VueToast);
Vue.use(VueLoading, {
  dark: true,
  text: 'Un instant . . .',
})

Vue.mixin({
  methods: {
    formatJson(FilterData, JsonData){
      return JsonData.map((v) => FilterData.map((j) => {
        return v[j];
      }))
    },
    exportExcel(data = [], name = "Fichier Excel") {

      var invoicesWS = XLSX.utils.json_to_sheet(data)

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new() // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, invoicesWS, name) // invoices list is name of Worksheet


      // export Excel file
      XLSX.writeFile(wb, name+'.xlsx')

    },
  },
});




for (const rule of Object.keys(rules)) extend(rule, rules[rule]);

extend('integer', {
  message: 'Ce champ doit être un nombre uniquement'
});

extend('containsUppercase', value => {
  if (/[A-Z]/.test(value)) {
    return true;
  }

  return 'Le champ {_field_} doit contenir au moins une lettre majuscule';
});

extend('codePays', value => {
  if (value.includes("+")) {
    return true;
  }

  return 'Le Pays doit être sélectionné';
});

extend('containsLowercase', value => {
  if (/[a-z]/.test(value)) {
    return true;
  }

  return 'Le champ {_field_} doit contenir au moins une lettre miniscule';
});

extend('containsNumber', value => {
  if (/[0-9]/.test(value)) {
    return true;
  }

  return 'Le champ {_field_} doit contenir au moins un nombre';
});

extend('email', {
  message: 'L\'e-mail doit être une adresse de format conforme'
});

localize(_fr.code, _fr);
moment.locale('fr');

const store = new Vuex.Store(stores), router = new Vuer(routes);

router.beforeEach((to, from, next) => {helps.middleware({ to, store, next });});

https.interceptors.response.use(undefined, err => helps.interceptor(err, store, router));

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')