import client from '@/services/';

const state = () => ({
    all: [],
    one: {},
    donne: []
});

const getters = {
    all: state => state.all,
    one: state => state.one,
    donne: state => state.donne
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
    SET_DONNE: (state, obj) => {
        state.donne = obj;
    }
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('contact');
        cxt.commit("SET_DONNE", data);
        return data;
    },
    async searchContacts(cxt, nomPrenoms) {
        try {
          let {data} = await client.get(`/contact/filtree/${nomPrenoms}`);
          cxt.commit("SET_DONNE", data);
          return data;
        } catch (error) {
          console.error('Erreur lors de la recherche des contacts :', error);
          throw error;
        }
    },
    async getAllP(cxt, payload = {page: 1, texte: ''}) {
        let { data } = payload.texte !== '' ? await client.get('contact/filtered/'+payload.page, {params: {texte: payload.texte}}) : await client.get('contact/'+payload.page);
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('contact/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('contact/edit/'+payload.id, payload) : await client.post('contact/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
        return data;    
    },
    async del(cxt, id) {
        let { data } = await client.delete('contact/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async search(cxt, {texte, page}) {
        let {data} = await client.get('contact/filtered/'+page, {
            params: { texte }
        });
        cxt.commit("SET_DONNE", data);
        return data;
    },
    async rechercheContact(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('contact/filtered/'+payload.page, {params: {texte: payload.texte}})
        cxt.commit("SET_DONNE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
