import client from '@/services/';

const state = () => ({
    pays: [],
    departements: [],
    communes: [],
    arrondissements: [],
    quartiers: [],
});

const getters = {
    pays: state => state.pays,
    departements: state => state.departements,
    communes: state => state.communes,
    arrondissements: state => state.arrondissements,
    quartiers: state => state.quartiers,
};

const mutations = {
    SET_PAYS: (state, obj) => {
        state.pays = obj;
    },
    SET_DEPARTEMENTS: (state, obj) => {
        state.departements = obj;
    },
    SET_COMMUNES: (state, obj) => {
        state.communes = obj;
    },
    SET_ARRONDISSEMENTS: (state, obj) => {
        state.arrondissements = obj;
    },
    SET_QUARTIERS: (state, obj) => {
        state.quartiers = obj;
    },
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
};

const actions = {
    async getPays(cxt, payload) {
        let { data } = await client.get('echelon/pays');
        cxt.commit("SET_PAYS", data);
        return data; 
    },
    async getDepartements(cxt, paysId) {
        let { data } = await client.get('echelon/departements/'+paysId);
        cxt.commit("SET_DEPARTEMENTS", data);
        return data;
    },
    async getCommunes(cxt, departementId) {
        let { data } = await client.get('echelon/communes/'+departementId);
        cxt.commit("SET_COMMUNES", data);
        return data;
    },
    async getArrondissements(cxt, communeId) {
        let { data } = await client.get('echelon/arrondissements/'+communeId);
        cxt.commit("SET_ARRONDISSEMENTS", data);
        return data;
    },
    async getQuartiers(cxt, arrondissementId) {
        let { data } = await client.get('echelon/quartiers/'+arrondissementId);
        cxt.commit("SET_QUARTIERS", data);
        return data;
    },
    async getAllQuartiers(cxt, payload) {
        let { data } = await client.get('echelon/quartiers');
        cxt.commit("SET_ALL", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
