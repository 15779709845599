import client from '@/services/';

const state = () => ({
    secteur: [],
    categorie: [],
    branche: [],
    activiteEntreprise: [],
});

const getters = {
    secteur: state => state.secteur,
    categorie: state => state.categorie,
    branche: state => state.branche,
    activiteEntreprise: state => state.activiteEntreprise,
};

const mutations = {
    SET_SECTEUR: (state, obj) => {
        state.secteur = obj;
    },
    SET_CATEGORIE: (state, obj) => {
        state.categorie = obj;
    },
    SET_BRANCHE: (state, obj) => {
        state.branche = obj;
    },
    SET_ACTIVITE_ENTREPRISE: (state, obj) => {
        state.activiteEntreprise = obj;
    },
};

const actions = {
    async getSecteur(cxt, payload) {
        let { data } = await client.get('echelonActiviteAhilido/secteur');
        cxt.commit("SET_SECTEUR", data);
        return data; 
    },
    async getCategorie(cxt, secteurId) {
        let { data } = await client.get('echelonActiviteAhilido/categorie/'+secteurId);
        cxt.commit("SET_CATEGORIE", data);
        return data;
    },
    async getBranche(cxt, categorieId) {
        let { data } = await client.get('echelonActiviteAhilido/branche/'+categorieId);
        cxt.commit("SET_BRANCHE", data);
        return data;
    },
    async getactiviteEntreprise(cxt, brancheId) {
        let { data } = await client.get('echelonActiviteAhilido/activiteEntreprise/'+brancheId);
        cxt.commit("SET_ACTIVITE_ENTREPRISE", data);
        return data;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
