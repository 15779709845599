// globalScript.js
document.addEventListener('DOMContentLoaded', function() {
    let menuToggle = document.getElementById('menu-toggle');
    let menuToggleClose = document.getElementById('menu-toggle-close');
    let wrapper = document.getElementById('wrapper');

    if (menuToggle) {
        menuToggle.addEventListener('click', function (e) {
            e.preventDefault();
            wrapper.classList.toggle("toggled");
            //alert('Bouton cliqué')
        });
    }

    if (menuToggleClose) {
        menuToggleClose.addEventListener('click', function (e) {
            e.preventDefault();
            wrapper.classList.toggle("toggled");
            //alert('Bouton cliqué')
        });
    }
});
