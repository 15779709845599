import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one,
    uploadedFile: state => state.uploadedFile,

};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
    SET_UPLOADED_FILE: (state, form) => {
        state.uploadedFile = form;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('serviceFourni');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('serviceFourni/'+payload.page, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('serviceFourni/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('serviceFourni/edit/'+payload.id, payload) : await client.post('serviceFourni/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
    
        return data;    
    },
    async del(cxt, id) {
        let { data } = await client.delete('serviceFourni/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async uploadFichier({ commit }, { serviceFourniId, formData }) {
        try {
            let { data } = await client.post(`pieceJointe/add/${serviceFourniId}`, formData);
            commit('SET_UPLOADED_FILE', data);
            return data;
        } catch (error) {
            console.error('Error uploading file:', error);
            throw error;
        }
    },
    async exportationMembre({commit}, id) {
        try {
            let {data} = await client.get('serviceFourni/export/'+id, { responseType: 'arraybuffer' });
      
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
            saveAs(blob, 'serviceFournis.xlsx');
      
            commit('SET_UPLOADED_FILE', data);
            } catch (error) {
            console.error(error);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
