import connexion from "./modules/connexion";
import client from "./modules/client";
import typeEchange from "./modules/typeEchange";
import typeEvenement from "./modules/typeEvenement";
import typeActualite from "./modules/typeActualite";
import typeActivite from "./modules/typeActivite";
import typeBesoin from "./modules/typeBesoin";
import role from "./modules/role";
import region from "./modules/region";
import portail from "./modules/portail";
import permissions from "./modules/permissions";
import paiements from "./modules/paiements";
import formeJuridique from "./modules/formeJuridique";
import fonction from "./modules/fonction";
import etatEntreprise from "./modules/etatEntreprise";
import echelon from "./modules/echelon";
import categorie from "./modules/categorie";
import besoin from "./modules/besoin";
import ambassade from "./modules/ambassade";
import assignation from "./modules/assignation";
import contact from "./modules/contact";
import user from "./modules/user";
import dirigeant from "./modules/dirigeant";
import uploadFile from "./modules/uploadFile";
import referentiel from "./modules/referentiel";
import membre from "./modules/membre";
import secteurActivite from "./modules/secteurActivite";
import activiteOhada from "./modules/activiteOhada";
import activiteEntreprise from "./modules/activiteEntreprise";
import sousActivite from "./modules/sousActivite";
import branche from "./modules/branche";
import categorieActivite from "./modules/categorieActivite";
import section from "./modules/section";
import division from "./modules/division";
import groupe from "./modules/groupe";
import classes from "./modules/classes";
import sousClasse from "./modules/sousClasse";
import produit from "./modules/produit";
import echelonActivite from "./modules/echelonActivite";
import echelonAhilido from "./modules/echelonAhilido";
import responsable from "./modules/responsable";
import statistique from "./modules/statistique";
import demandes from "./modules/demandes";
import grilleTarifaire from "./modules/grilleTarifaire";
import statut from "./modules/statut";
import modeTraitement from "./modules/modeTraitement";
import moyenPaiement from "./modules/moyenPaiement";
import naturePiece from "./modules/naturePiece";
import fichierController from "./modules/fichierController";
import service from "./modules/service";
import typeServices from "./modules/typeServices";
import requetesMembre from "./modules/requetesMembre";
import sourceFinancement from "./modules/sourceFinancement";
import programme from "./modules/programme";
import projet from "./modules/projet";
import categorieSaf from "./modules/categorieSaf";
import saf from "./modules/saf";
import serviceFourni from "./modules/serviceFourni";
import groupement from "./modules/groupement";
import mandature from "./modules/mandature";
import partenaire from "./modules/partenaire";
import logs from "./modules/logs";
import donneeFinanciere from "./modules/donneeFinanciere";
import devise from "./modules/devise";
import referent from "./modules/referent";
import entreprises from "./modules/entreprises";
import serviceFourniEntreprise from "./modules/serviceFourniEntreprise";



const store = {
  modules: {
    typeServices: typeServices,
    grilleTarifaire: grilleTarifaire,
    demandes: demandes,
    typeEvenement: typeEvenement,
    connexion: connexion,
    client: client,
    user: user,
    ambassade: ambassade,
    assignation: assignation,
    besoin: besoin,
    categorie: categorie,
    echelon: echelon,
    contact: contact,
    etatEntreprise: etatEntreprise,
    fonction: fonction,
    formeJuridique: formeJuridique,
    paiements: paiements,
    permissions: permissions,
    role: role,
    typeActualite: typeActualite,
    typeActivite: typeActivite,
    typeBesoin: typeBesoin,
    region: region,
    portail: portail,
    typeEchange: typeEchange,
    dirigeant: dirigeant,
    uploadFile : uploadFile,
    referentiel: referentiel,
    membre: membre,
    secteurActivite: secteurActivite,
    activiteOhada: activiteOhada,
    activiteEntreprise: activiteEntreprise,
    sousActivite: sousActivite,
    branche: branche,
    categorieActivite: categorieActivite,
    section: section,
    division: division,
    groupe: groupe,
    classes: classes,
    sousClasse: sousClasse,
    produit: produit,
    echelonActivite: echelonActivite,
    echelonAhilido: echelonAhilido,
    responsable: responsable,
    statistique: statistique,
    statut: statut,
    modeTraitement: modeTraitement,
    moyenPaiement: moyenPaiement,
    naturePiece: naturePiece,
    fichierController: fichierController,
    service: service,
    requetesMembre: requetesMembre,
    sourceFinancement: sourceFinancement,
    programme: programme,
    projet: projet,
    categorieSaf: categorieSaf,
    saf: saf,
    serviceFourni: serviceFourni,
    groupement: groupement,
    mandature: mandature,
    partenaire: partenaire,
    logs: logs,
    donneeFinanciere: donneeFinanciere,
    devise: devise,
    referent: referent,
    entreprises: entreprises,
    serviceFourniEntreprise: serviceFourniEntreprise,

  },
};

export default store;
