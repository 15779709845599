<template>
  <div id="app">
    <router-view />

    <script type="application/javascript" src="/assets/js/bootstrap.bundle.min.js"></script>
    <script type="application/javascript" src="/assets/js/select2.min.js"></script>
  </div>
</template>

<script>
import Toast from './components/Toast.vue';

export default {
  name: 'App',
  mounted(){
    var tableau = [
      "/assets/js/jquery.min.js",
      "/assets/js/select2.min.js",
    ];

    for (let i = 0; i < tableau.length; i++) {
      const element = tableau[i];
      var imp = document.createElement('script');
      imp.src = element;
      document.head.appendChild(imp); 
    }
  }
 
}
</script>

<style lang="scss">
@import './assets/css/bootstrap.css';
@import './assets/css/bootstrap-customer.css';
@import './assets/css/bootstrap-icons.css';
@import './assets/css/style.css';
@import './assets/css/select2-bootstrap-5-theme.css';
@import './assets/css/select2.min.css';
@import '~sweetalert2/dist/sweetalert2.min.css';
@import '~primevue/resources/themes/bootstrap4-light-blue/theme.css';
@import '~primevue/resources/primevue.min.css';
@import '~primeicons/primeicons.css';
@import '~vue-multiselect/dist/vue-multiselect.min.css';

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary:visited {
  background-color: #e66820 !important;
  border-color: #e66820 !important;
}
</style>
