import client from '@/services/';

const state = () => ({
    all: [],
    one: {},
    dirigeant: []
});

const getters = {
    all: state => state.all,
    one: state => state.one,
    dirigeant: state => state.dirigeant
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
    SET_DIRIGEANT: (state, obj) => {
        state.dirigeant = obj;
    }
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('referentiel');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: '', size: 7, regionId: '', formeJuridiqueId: '', etatEntrepriseCode: '', startDate: '', endDate: '', ifu: '', raisonSociale: ''},) {
        let { data } = payload.texte !== '' ? await client.get('referentiel/filtered/'+payload.page, {params: {texte: payload.texte}}) : await client.get('referentiel/'+payload.page+'/'+payload.size , {params: {formeJuridiqueId: payload.formeJuridiqueId, regionId: payload.regionId, ifu: payload.ifu, raisonSociale: payload.raisonSociale, etatEntrepriseCode: payload.etatEntrepriseCode, startDate: payload.startDate, endDate: payload.endDate}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('referentiel/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('referentiel/edit/'+payload.id, payload) : await client.post('referentiel/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('referentiel/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async transfert(cxt, payload){
        let {data} = await client.post('membre/transfert/referentiel', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async dirigeantReferentiel(cxt, ifuEntreprise){
        let { data } = await client.get('/contact/referentiel/'+ ifuEntreprise);
        cxt.commit("SET_DIRIGEANT", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
