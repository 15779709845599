import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

// const actions = {
//     async getAll(cxt, payload) {
//         let { data } = await client.get('dirigeant');
//         cxt.commit("SET_ALL", data);
//         return data;
//     },
//     async getAllP(cxt, payload = {}) {
//         let { data } = await client.get('dirigeant/'+payload);
//         cxt.commit("SET_ALL", {
//             loaded: true, ...data, number: data.number+1,
//             content: data.content || []
//         })
//         //cxt.commit("SET_ALL", data);
//         return data;
//     },
//     async getOne(cxt, id) {
//         let { data } = await client.get('dirigeant/one/'+id);
//         cxt.commit("SET_ONE", data);
//         return data;
//     },
//     async create(cxt, payload) {
//         let { data } = payload.id ? await client.put('dirigeant/edit/'+payload.id, payload) : await client.post('dirigeant/add', payload);
//         cxt.commit("SET_ONE", data);
//         if (payload.id) {
//             payload.id = null;
//         }
    
//         return data;    },
//     async del(cxt, id) {
//         let { data } = await client.delete('dirigeant/delete/'+id);
//         cxt.commit("SET_ONE", data);
//         return data;
//     },
//     async searchContact(cxt, {contactId}){
//         let {data} = await client.get('dirigeant/contact/'+ contactId)
//         cxt.commit("SET_ALL",data);
//         return data
//     },
//     async searchFonction(cxt, {fonctionId}){
//         let {data} = await client.get('dirigeant/fonction/'+ fonctionId)
//         cxt.commit("SET_ALL",data);
//         return data
//     },
//     async searchClient(cxt, {clientId}){
//         let {data} = await client.get('dirigeant/entreprise/'+ clientId)
//         cxt.commit("SET_ALL",data);
//         return data
//     }, 
// };

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('dirigeantClient');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = { page: 1, texte: '' }) {
        let { data } = await client.get('dirigeantClient/'+payload.page, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('dirigeantClient/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
