import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getMembreStatistiqueRegion(cxt, payload) {
        let { data } = await client.get('/membre/statistiques/region');
        cxt.commit("SET_ALL", data);
        return data;
    },

    async getMembreStatistiqueformeJuridique(cxt, payload) {
        let { data } = await client.get('/membre/statistiques/formeJuridique');
        cxt.commit("SET_ALL", data);
        return data;
    },

    async getReferentielStatistiqueRegion(cxt, payload) {
        let { data } = await client.get('/referentiel/statistiques/region');
        cxt.commit("SET_ALL", data);
        return data;
    },

    async getReferentielStatistiqueformeJuridique(cxt, payload) {
        let { data } = await client.get('/referentiel/statistiques/formeJuridique');
        cxt.commit("SET_ALL", data);
        return data;
    },

    async getClientStatistiqueRegion(cxt, payload) {
        let { data } = await client.get('/client/statistiques/region');
        cxt.commit("SET_ALL", data);
        return data;
    },

    async getClientStatistiqueformeJuridique(cxt, payload) {
        let { data } = await client.get('/client/statistiques/formeJuridique');
        cxt.commit("SET_ALL", data);
        return data;
    },

    async getAllStatistique(cxt, payload) {
        let { data } = await client.get('/statistiques/all');
        cxt.commit("SET_ALL", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
