import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('projet');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('projet/' + payload.page, {params: {texte: payload.texte}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number + 1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('projet/one/' + id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        const response = payload.id
            ? await client.put('projet/edit/' + payload.id, payload)
            : await client.post('projet/add', payload, { params : {programmeId: payload.programmeId, sourceFinancementId: payload.sourceFinancementId, deviseId: payload.deviseId, referentId: payload.referentId, utilisateurId: payload.utilisateurId}});
        cxt.commit("SET_ONE", response.data);

        if (payload.id) {
            payload.id = null;
        }

        return response.data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('projet/delete/' + id);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
