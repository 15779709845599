import client from '@/services/';

const state = () => ({
    all: [],
    one: {}
});

const getters = {
    all: state => state.all,
    one: state => state.one
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('activiteEntreprise');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {}) {
        let { data } = await client.get('activiteEntreprise/'+payload);
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('activiteEntreprise/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('activiteEntreprise/edit/'+payload.id, payload) : await client.post('activiteEntreprise/add', payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
    
        return data;    },
    async del(cxt, id) {
        let { data } = await client.delete('activiteEntreprise/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
