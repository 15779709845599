<template>
    <div class="">
      <BaseCalendar
        :value="innerValue"
        v-bind="$attrs"
        v-on="{ ...$listeners, input: onInput, dateSelect: dateSelected }"
        v-if="!isDateInput"
      />
      <DatePicker
        class="flex w-full p-fluid"
        :mode="mode"
        :is24hr="true"
        v-else
        :placeholder="placeholder"
        :first-day-of-week="2"
        :masks="{ title: 'MMM YYYY' }"
        locale="fr"
        :value="value"
        :dateFormat="computedDateFormat"
        v-bind="$attrs"
        is-expanded
        v-on="{ ...$listeners }"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="form-floating mb-3 flex w-full" v-if="styleMode != 'prime'">
            <input
              class="form-control"
              :value="inputValue"
              required
              type="text"
              v-on="inputEvents"
            />
            <label for="titre">{{ placeholder }}</label>
          </div>
          <div class="p-input-icon-right p-fluid" v-else>
            <PInputText
              :value="inputValue"
              :placeholder="placeholder"
              required
              ref="dateInputic"
              class="form-control"
              type="text"
              v-on="inputEvents"
            />
            <!-- @click="$refs.dateInputic.click()" -->
            <i class="pi pi-calendar" v-on="inputEvents" />
          </div>
        </template>
      </DatePicker>
    </div>
  </template>
  
  <script>
  Date.prototype.toISOString = function () {
    let tzo = -this.getTimezoneOffset(),
      dif = tzo >= 0 ? "+" : "-",
      pad = function (num) {
        var norm = Math.floor(Math.abs(num));
        return (norm < 10 ? "0" : "") + norm;
      };
  
    return (
      this.getFullYear() +
      "-" +
      pad(this.getMonth() + 1) +
      "-" +
      pad(this.getDate()) +
      "T" +
      pad(this.getHours()) +
      ":" +
      pad(this.getMinutes()) +
      ":" +
      pad(this.getSeconds()) +
      "Z"
    );
    // dif + pad(tzo / 60) +
    // ':' + pad(tzo % 60);
  };
  
  import BaseCalendar from "primevue/calendar";
  import DatePicker from "v-calendar/lib/components/date-picker.umd";
  
  export default {
    props: {
      value: {
        type: [String, Object, Array],
      },
      dateFormat: {
        type: String,
        default: "DD/MM/YYYY",
      },
      mode: {
        type: String,
        default: "date",
      },
      placeholder: {
        type: String,
        default: "",
      },
      styleMode: {
        type: String,
        default: "prime", //prime - floating
      },
    },
    components: {
      BaseCalendar,
      DatePicker,
    },
    mixins: [],
    data() {
      return {};
    },
    created() {},
    watch: {
      value: {
        handler(val) {},
        immediate: true,
        deep: true,
      },
      dateFormat(val) {},
    },
    computed: {
      innerValue() {
        if (this.value == null) return;
  
        //   let dateFormat = this.dateFormat;
        //   if (dateFormat == "MM yy") dateFormat = "MMMM YYYY";
        //   if (dateFormat == "dd/mm/yy") dateFormat = "DD/MM/YYYY";
        return this.value != null
          ? this.$moment(this.value).format(this.computedDateFormat.toUpperCase())
          : null;
      },
      isDateInput() {
        return this.dateFormat === "dd/mm/yy" || this.dateFormat === "DD/MM/YYYY";
      },
      computedDateFormat() {
        let dateFormat = this.dateFormat;
        if (dateFormat == "MM yy") dateFormat = "MMMM YYYY";
        if (dateFormat == "dd/mm/yy") dateFormat = "DD/MM/YYYY";
        return dateFormat;
      },
    },
    created() {},
    methods: {
      onInput(date) {
        new Date(
          Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds()
          )
        );
        this.$emit("input", date);
      },
      dateSelected(data) {},
      // updateModel(value) {
      //   if (this.outputFormat && value) {
      //     if (Array.isArray(value))
      //       value = value.map((date) =>
      //         date ? this.formatDate(date, this.outputFormat) : date
      //       );
      //     else value = this.formatDate(value, this.outputFormat);
      //   }
  
      //   // this.$emit('input', value);
      // },
    },
  };
  </script>
  
  <style></style>
  