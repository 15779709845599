<template>
    <div class="toast-container position-fixed top-0 end-0 p-3">
        <div :class="['toast', toastType]" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="me-auto">{{ title }}</strong>
                <small>{{ timestamp }}</small>
                <button type="button" class="btn-close" @click="closeToast" aria-label="Close"></button>
            </div>
            <div class="toast-body text-white">
                {{ message }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        message: String,
        toastType: {
            type: String,
            default: 'bg-success'
        },
        timestamp: {
            type: String,
            default: '11 mins ago'
        }
    },
    methods: {
        closeToast() {
            this.$emit('close');
        }
    }
};
</script>
