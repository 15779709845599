import client from '@/services/';
import { saveAs } from 'file-saver';
import service from './service';


const state = () => ({
    all: [],
    one: {},
    close: [],
    uploadedFile: null,
    service: [], 
    groupement: [],
    mandature: [],
    donneFinanciere: [],
    fichier: []
});

const getters = {
    all: state => state.all,
    one: state => state.one,
    close: state => state.close,
    service: state => state.service,
    groupement: state => state.groupement,
    mandature: state => state.mandature,
    donneFinanciere: state => state.donneFinanciere,
    fichier: state => state.fichier
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
    SET_UPLOADED_FILE: (state, obj) => {
        state.uploadedFile = obj;
    },
    SET_CLOSE: (state, obj) => {
        state.close = obj;
    },
    SET_SERVICE: (state, obj) => {
        state.service = obj;
    },
    SET_GROUPEMENT: (state, obj) => {
        state.groupement = obj;
    },
    SET_MANDATURE: (state, obj) => {
        state.mandature = obj;
    },
    SET_DONNE_FINANCIERE: (state, obj) => {
        state.donneFinanciere = obj;
    },
    SET_FICHIER: (state, obj) => {
        state.fichier = obj;
    }
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('membre');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: '', size: 7, regionId: '', formeJuridiqueId: '', produitId: '', ifu: '', raisonSociale: '', startDate: '', endDate: ''},) {
        let { data } = payload.texte !== '' ? await client.get('membre/filtered/'+payload.page, {params: {texte: payload.texte}}) : await client.get('membre/'+payload.page+'/'+payload.size , {params: {formeJuridiqueId: payload.formeJuridiqueId, produitId: payload.produitId, regionId: payload.regionId, ifu: payload.ifu, raisonSociale: payload.raisonSociale, startDate: payload.startDate, endDate: payload.endDate}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllPage(cxt, payload = {}) {
        let { data } = await client.get('membre/'+payload);
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllAssignation(cxt, payload = {page: 1, ifu: '', raisonSociale: '', regions: [], formeJuridiqueId: '', affected: false, produitId: ''}) {
        let { data } = await client.post('membre/assignation/filtered/'+payload.affected+'/'+payload.page+'/10', payload.regions, {params: {ifu: payload.ifu || '', raisonSociale: payload.raisonSociale || '', regionId: payload.regionId || '', formeJuridiqueId: payload.formeJuridiqueId || '', produitId: payload.produitId || ''}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getOne(cxt, id) {
        let { data } = await client.get('membre/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = await client.put('membre/edit/'+payload.id, payload);
        cxt.commit("SET_ONE", data);
        if (payload.id) {
            payload.id = null;
        }
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('membre/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async percent(cxt, ifu){
        let { data } = await client.get('/membre/pourcentage/'+ ifu);
        cxt.commit("SET_CLOSE", data);
        return data;
    },
    async createCompte(cxt, id) {
        let {data} = await client.get('/membre/create/account/' + id);
        cxt.commit("SET_ALL", data);
        return data;
    },
    async exportationMembre({commit}) {
        try {
            let {data} = await client.get('/membre/export', { responseType: 'arraybuffer' });
      
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
            saveAs(blob, 'liste_des_membres.xlsx');
      
            commit('SET_UPLOADED_FILE', data);
            } catch (error) {
            console.error(error);
        }
    },
    async getMembreFile(cxt, ifu) {
        let { data } = await client.get('membre/fichiers/'+ifu);
        cxt.commit("SET_FICHIER", data);
        return data;
    },
    async getStatistique(cxt) {
        let { data } = await client.get('/membre/statistiques/actif');
        cxt.commit("SET_CLOSE", data);
        return data;
    },
    async rechercheMembre(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('membre/filtered/'+payload.page, {params: {texte: payload.texte}})
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async serviceFourniList(cxt, payload = {page: 1, size: 7, ifu: ''}) {
        let { data } = await client.get('/serviceFourni/'+payload.page+'/'+payload.size+ '/'+ payload.ifu);
        cxt.commit("SET_SERVICE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async groupementList(cxt, payload = {page: 1, size: 7, ifu: ''}) {
        let { data } = await client.get('/groupement/'+payload.page+'/'+payload.size+ '/'+ payload.ifu);
        cxt.commit("SET_GROUPEMENT", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async mandatureList(cxt, payload = {page: 1, size: 7, ifu: ''}) {
        let { data } = await client.get('/mandature/'+payload.page+'/'+payload.size+ '/'+ payload.ifu);
        cxt.commit("SET_MANDATURE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async donneFinanciere(cxt, payload = {page: 1, ifu: ''}) {
        let { data } = await client.get('/membre/dgi/'+payload.ifu+'/'+payload.page);
        cxt.commit("SET_DONNE_FINANCIERE", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    }


};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};