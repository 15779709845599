import client from '@/services/';

const state = () => ({
    all: [],
    allClient: [],
    one: {},
    dirigeant: []
});

const getters = {
    all: state => state.all,
    allClient: state => state.allClient,
    one: state => state.one,
    dirigeant: state => state.dirigeant
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_ALL_CLIENT: (state, obj) => {
        state.allClient = obj;
    },
    SET_ONE: (state, obj) => {
        state.one = obj;
    },
    SET_DIRIGEANT: (state, obj) => {
        state.dirigeant = obj;
    }
};

const actions = {
    async getAll(cxt, payload) {
        let { data } = await client.get('client');
        cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllClients(cxt, payload = {page: 1, texte: ''}) {
        let { data } = await client.get('client/membres/'+payload.page);
        cxt.commit("SET_ALL_CLIENT", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async getAllP(cxt, payload = {page: 1, texte: '', size: 7, regionId: '', formeJuridiqueId: ''}) {
        let { data } = await client.get('client/'+payload.page, {params: {texte: payload.texte, formeJuridiqueId: payload.formeJuridiqueId, regionId: payload.regionId}});
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },
    async getAllPage(cxt, payload = {}) {
        let { data } = await client.get('client/'+payload);
        cxt.commit("SET_ALL", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        //cxt.commit("SET_ALL", data);
        return data;
    },

    async getOne(cxt, id) {
        let { data } = await client.get('client/one/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async create(cxt, payload) {
        let { data } = payload.id ? await client.put('client/edit/'+payload.id, payload) : await client.post('client/add', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async createAccount(cxt, payload) {
        let { data } = await client.get('client/create/account/' + payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async del(cxt, id) {
        let { data } = await client.delete('client/delete/'+id);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async transfert(cxt, payload){
        let {data} = await client.post('membre/transfert/ahilido', payload);
        cxt.commit("SET_ONE", data);
        return data;
    },
    async dirigeantClient(cxt, ifuEntreprise){
        let { data } = await client.get('/dirigeantClient/ifu/'+ ifuEntreprise);
        cxt.commit("SET_DIRIGEANT", data);
        return data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
