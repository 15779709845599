import client from '@/services/';

const state = () => ({
    all: [],
    import: [],
    uploadedFile: null 
});

const getters = {
    all: state => state.all,
    import: state => state.import,
    uploadedFile: state => state.uploadedFile
};

const mutations = {
    SET_ALL: (state, obj) => {
        state.all = obj;
    },
    SET_IMPORT: (state, obj) => {
        state.import = obj;
    },
    SET_UPLOADED_FILE: (state, obj) => {
        state.uploadedFile = obj;
    },
};

const actions = {
    // async getAll(cxt, payload) {
    //     let { data } = await client.get('branche');
    //     cxt.commit("SET_ALL", data.data);
    //     return data;
    // },
    async getAllP(cxt, payload = { page: 1, texte: '', size: 7, startDate: '', endDate: '' }) {
        let data;
        if (payload.startDate !== '' && payload.endDate !== '') {
            data = await client.get('/logs/' + payload.page + '/' + payload.size, {
                params: { startDate: payload.startDate, endDate: payload.endDate }
            });
        } else {
            data = await client.get('/logs/all/' + payload.page + '/' + payload.size+ '/');
        }
        const response = data.data
        cxt.commit("SET_ALL", {
            loaded: true,
            ...response,
            number: response.number + 1,
            content: response.content || []
        });
        return response;
    },
    async getImportation(cxt, payload = {}) {
        let { data } = await client.get('/file/importations/'+payload);
        cxt.commit("SET_IMPORT", {
            loaded: true, ...data, number: data.number+1,
            content: data.content || []
        })
        return data;
    },
    async exportation({commit}, id) {
        try {
            let {data} = await client.get('/file/download/rapport/'+id, { responseType: 'arraybuffer' });
      
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      
            saveAs(blob, 'fichiersJournalisation.xlsx');
      
            commit('SET_UPLOADED_FILE', data);
            } catch (error) {
            console.error(error);
        }
    },
    
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
